<template>
  <div>
    <div class="pt-12 sm:pt-16 lg:pt-20">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="text-center">
          <h2 class="text-white md:text-center text-5xl lg:text-6xl font-bold leading-none sm:leading-tighter">
            <span class="inline-flex relative text-white">
              <span aria-hidden="" class="absolute inset-0 bg-showman" style="transform: skewY(-1.3deg) scaleX(1.05) scaleY(1.05);"></span>
              <span class="relative">Schedule</span>
            </span>
           a demo
          </h2>
          <p class="mt-4 text-xl text-gray-300">
            Be sure to check out the other resources on this page that may also help answer your questions.
          </p>
        </div>
      </div>
    </div>
    <div class="max-w-5xl mx-auto items-center flex flex-col sm:flex-row my-16 sm:my-24">
      <div class="text-center mr-6">
        <p class="text-gray-100 text-2xl sm:text-3xl font-medium tracking-tight">Before scheduling a demo, check out this short 5 minute overview video that may answer your questions.</p>
        <p class="mt-4 sm:text-lg text-gray-400">Have a specific question you want answered? Reach out to our <a class="link" href="mailto:support@showman.app" target="_blank"> support team.</a></p>
      </div>
      <!--<iframe class="rounded-lg mx-auto mt-4 sm:mt-0" src="https://player.vimeo.com/video/605171753?h=b9be0c1b9a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="300" height="184" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>-->
      <iframe class="rounded-lg mx-auto mt-4 sm:mt-0" src="https://player.vimeo.com/video/732759892?h=1791613136&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="300" height="184" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="General Overview.mp4"></iframe>
    </div>
    <div id="booking-page" class= "sm:max-w-6xl mx-4 lg:mx-auto">
    </div>
    <FAQ />
    <div class="bg-gray-900">
      <div class="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 class="text-3xl font-extrabold tracking-tight text-gray-100 sm:text-4xl">
          <span class="block">Ready to get started on your show?</span>
        </h2>
        <div class="mt-8 flex justify-center">
          <div class="inline-flex rounded-lg shadow">
            <a onclick="fathom.trackGoal('PPBJ1VUV', 0);" href="/shows/#/new-show-signup" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-bold rounded-lg text-white bg-showman hover:bg-orange-500">
              Start my show set up!
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import FAQ from './FAQ.vue'

export default {
  components: {
    FAQ
  },
  mounted () {
    window.SavvyCal('inline', {
      link: 'showman/demo',
      selector: '#booking-page'
    })
  }
}
</script>

<style>

</style>
